import { createRoot } from "react-dom/client";

const container = document.getElementById("root");

if (window._APP_CONFIG) {
  // env.js
  if (container != null) {
    const root = createRoot(container);
    import("./app").then(({ App }) => {
      root.render(<App />);
    });
  }
} else {
  // env.json
  fetch(`${location.origin}/env.json`)
    .then((response) => response.json())
    .then((json) => {
      window._APP_CONFIG = json;
      if (container != null) {
        const root = createRoot(container);
        import("./app").then(({ App }) => {
          root.render(<App />);
        });
      }
    });
}
